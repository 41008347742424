import React, { useRef } from 'react';
import styles from './OptionsViewPopup.module.css';
import { CrossIcon, GreenTickIcon, PottyIcon, RedCrossIcon, VaccinatedIcon, WeightIcon } from '../../../../assets/SVG';
import { BACKEND_URL } from "../../../../Utilities/Constant";


const OptionsViewPopup = ({ onClose,petData }) => {
  const popupRef = useRef(null);
  return (
    <div className={styles.Background}>
      <div style={{ position: 'relative' }}>
        <div className={styles.popupContainer} ref={popupRef}>
          <div>
            <img src={`${BACKEND_URL}${petData.petImage}`} a alt="Victoria" style={{  borderTopLeftRadius: "0px",
     borderTopRightRadius: "0px"}}/>
          </div>
          <div className={styles.mainContainer}>
          {petData.petName && <h1 style={{ marginBottom: "10px" }}>{petData.petName}</h1>}
{petData.petName && petData.breed && (
  <p style={{ marginBottom: "10px" }}>
    Breed Name <span>&nbsp;</span>: <span>&nbsp;</span>{petData.breed}
  </p>
)}
{petData.petAge && (
  <p style={{ marginBottom: "10px" }}>
    <span >Age </span><span style={{marginLeft:"35px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>: <span>&nbsp;</span>{petData.petAge}<span>&nbsp;</span>
  </p>
)}
{petData.petGender && (
  <p style={{ marginBottom: "10px" }}>
    Gender <span style={{marginLeft:"15px"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>: <span>&nbsp;</span>{petData.petGender}<span>&nbsp;</span>
  </p>
)}
{petData.location && (
  <p style={{ marginBottom: "10px" }}>
    Location <span style={{marginLeft:"27px"}}>:</span><span>&nbsp;</span> {petData.location}<span>&nbsp;</span>
  </p>
)}
{petData.studFee && (
  <p> 
    Stud Fee <span style={{marginLeft:"27px",marginTop:"-2px"}}>:</span> <span style={{ color: '#F5790C', fontSize: '16px', fontWeight: 600,marginLeft:"2px",marginTop:"3px",paddingTop:"2px" }}>
       {petData.studFee}/-
    </span>
  </p>
)}

            <div className={styles.line}></div>
            <div className={styles.featuresDiv} style={{marginBottom:"15px"}}>
              <div style={{ display: 'flex',marginBottom:"10px" }}>
                <WeightIcon />
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>{petData.weight} kgs</p>
                {!petData.vaccinated || petData.vaccinated === "No" ? <RedCrossIcon /> : <VaccinatedIcon />}
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>Vaccinated</p>
                {!petData.isYourPetTrained||petData.isYourPetTrained==="No"?<RedCrossIcon />:<PottyIcon />}
                <p style={{ marginLeft: '10px', marginRight: '50px' }}>Potty Trained</p>
              </div>
              <div style={{ display: 'flex' }}>
              {!petData.isYourPetGoodWithKids||petData.isYourPetGoodWithKids==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Good with Kids</p>
                {!petData.isYourPetGoodWithDogs||petData.isYourPetGoodWithDogs==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Good with Dogs</p>
                {!petData.isYourPetGoodWithCats||petData.isYourPetGoodWithCats==="No"?<RedCrossIcon />: <GreenTickIcon />}
                <p style={{ marginLeft: '10px', marginRight: '15px' }}>Not Good With Cats</p>
              </div>
            </div> 
            <p style={{marginLeft:"2px"}}>He is nice and he will get temperament at seeing other animals</p>
          </div>
          <div className={styles.crossButton} onClick={onClose}>
            <CrossIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsViewPopup;
