import React, { useState, useEffect } from "react";
import styles from "./Tinder.module.css";
import { petInfo } from "./constants";
import PetProfileCard from "./PetProfileCard";
import UpdateDetailsPopup from "./Popups/UpdateDetailsPopup/UpdateDetailsPopup";
import AddNewPetPopup from "./Popups/AddNewPetPopup/AddNewPetPopup";
import { WebApimanager } from "../../WebApiManager";
import { useNavigate } from "react-router";
import TinderMatches from "./TinderMatches";

const TinderHomePage = () => {
  const navigate = useNavigate();
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setIsUpdatePopupOpen] = useState(false);
  const [tempData, setTempData] = useState([]);
  const webApi = new WebApimanager();
  const [selectedCardData, setSelectedCardData] = useState({});
  const [allPetData,setAllPetData]=useState(null) 

  useEffect(() => {
    webApi
      .get("petProfile/ByPetOwner")
      .then((response) => {
        const pets = response.data.data.pets;
        setTempData(pets);
        console.log("pets", pets);
        setAllPetData(pets)
      })
      .catch((error) => {
        console.error("Error fetching pet data:", error);
      });
  }, []);
  useEffect(() => {
    console.log(selectedCardData, "selectedCardData");
  }, [selectedCardData]);

  return (
    <>
      <section className={styles.TinderHomePage}>
        <div className={styles.profileCardDiv}>
          {tempData.map((petData) => {
            return (
              <PetProfileCard
                petData={petData}
                allPetData={allPetData}
                setIsUpdatePopupOpen={setIsUpdatePopupOpen}
                key={petData.id}
                setSelectedCardData={setSelectedCardData}
              />
            );
          })}
          <div className={styles.addCard}>
            <div
              className={styles.addButtonDiv}
              onClick={() => setIsAddPopupOpen(true)}
            >
              <p>+</p>
            </div>
            <p style={{ fontSize: "13px", fontWeight: 600 }}>Add Another Pet</p>
          </div>
        </div>
        <div className={styles.warningDiv}>
          <h1 style={{marginBottom:"18px"}}>Be Alert: Beware of scammers.</h1>
          <p style={{marginBottom:"18px"}}>
            Please avoid making any advance payments to other members without
            physically verifying the pet yourself. As Petmeetly does not act as
            an intermediary in financial transactions between members, we are
            unable to assist you in the event of financial loss due to scams.
          </p>
          <p style={{marginBottom:"18px"}}>
            Please refrain from accepting advance payments through services like
            Zelle, Venmo, etc., even if it seems like quicker way to close the
            deal. For tips on protecting yourself from scammers, you can refer
            to this article: zelle-scams (Note: We are not affiliated with
            aura).
          </p>
          <p  style={{marginBottom:"18px"}}>
            Report suspicious activity immediately to support@Zaanvar.com. Keep
            our community safe!
          </p>
        </div>
        <UpdateDetailsPopup
          isUpdatePopupOpen={isUpdatePopupOpen}
          setIsUpdatePopupOpen={setIsUpdatePopupOpen}
          selectedCardData={selectedCardData}
        />
        <AddNewPetPopup
          isAddPopupOpen={isAddPopupOpen}
          setIsAddPopupOpen={setIsAddPopupOpen}
        />
      </section>
    </>
  );
};

export default TinderHomePage;
