import React, { useState, useRef, useEffect } from "react";
import styles from "./Tinder.module.css";
import { useNavigate } from "react-router";
import {ChatIcon,EditIcon,LikeIcon,LikedIcon,LocationIcon,MenuIcon,} from "../../assets/SVG";
import OptionsPopup from "./Popups/OptionsPopup/OptionsPopup";
import NotCompletedPopup from "./Popups/NotCompletedPopup/NotCompletedPopup";
import { BACKEND_URL, formatDate } from "../../Utilities/Constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebApimanager } from "../../WebApiManager";

const PetProfileCard = ({ petData, type,setIspetInfoPopupOpen,setIsUpdatePopupOpen,setIsChatPopupOpen, sendPetData,onFindMatches,setSelectedCardData,allPetData,oldPetId, onCardClick}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
  const [isNCPopupOpen, setIsNCPopupOpen] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [location, setLocation] = useState(petData.location);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedPetData, setSelectedPetData] = useState({});
  const webApi = new WebApimanager();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPetInfo,setSelectedPetInfo]=useState(null)
  const [apiProcessing, setApiProcessing] = useState({
    loader: false,
    message: "Loading...",
  });
  useEffect(() => {
  }, [oldPetId]);
  const isButtonDisabled = !petData.petNeed && !petData.livePet;
  const handleMenuClick = () => {
    setSelectedPetData(petData);
    setIsOptionsMenuOpen(!isOptionsMenuOpen);
  };

  const closeOptionsMenu = () => {
    setIsOptionsMenuOpen(false);
  };

 const handleClickLike = async(newPetId,currentSavedState) => {
 const payload={
    favouritePetId:newPetId
 }
 let url;
 if (currentSavedState === "yes") {
   url = `petProfile/deleteFavPet/${oldPetId}`;

 } else {
   url = `petProfile/updateFavPet/${oldPetId}`;

 }

 try {
   const response = currentSavedState === "yes" 
   ? await webApi.delete(url,payload)  
   : await webApi.put(url,payload);   
   console.log(response, "responseresponse");
 
 toast.success("Successfully!");
  window.location.reload();
} catch (err) {
 console.error("Error occurred:", err);
 setError("Something went wrong. Please try again later.");
} finally {
 setLoading(false);
}
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };
  const handleEditLocation = () => {
    setIsEditingLocation(true);
  };

  const handleLocationSubmit = async () => {
    const payload = {
      location: location,
    };
    setApiProcessing({
      loader: true,
      message: "Requesting...",
    });
    try {
      const response = await webApi.put(
        `petProfile/update/${petData._id}`,
        payload
      );
      console.log(response, "responseresponse");
      toast.success("location Update Successfully!");
    } catch (err) {
      console.error("Error occurred:", err);
      setError("Something went wrong. Please try again later.");
      toast.error("This is an error message!");
    } finally {
      setLoading(false);
    }
    setIsEditingLocation(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeOptionsMenu();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFindMatchesClick = () => {
    if (onFindMatches) {
      onFindMatches(petData);
      setSelectedPetData(petData);
    }
    console.log(petData, "check pet card");
    navigate("/tinder/matches", { state: { selectedPet: petData, oldpetid:petData._id,selectedPetInfo } });
  };
  useEffect(() => {
    if (Object.keys(selectedPetData).length > 0) {
      setSelectedCardData(selectedPetData);
    }
  }, [selectedPetData]);

  const calculateProfileCompletion = (pet) => {
    const fields = [
      pet.DNACertificate,
      pet.DNATested,
      pet.birthday,
      pet.breed,
      pet.color,
      pet.description,
      pet.hasYourPetBeenTestedForAnyBreedSpecificHealthConditionsOrGeneticDisOrders,
      pet.livePet,
      pet.location,
      pet.pedigreeCertificate,
      pet.pedigreeCertified,
      pet.petAge,
      pet.petGender,
      pet.petImage,
      pet.vaccinated,
      pet.vaccineCertificate,
      pet.weight,
    ];
    const totalFields = fields.length;
    const weightPerField = 100 / totalFields;
    const validFieldsCount = fields.filter((field) => {
      if (Array.isArray(field)) {
        return field.length > 0;
      }
      return field && field !== "";
    }).length;
    let basePercentage = validFieldsCount * weightPerField;
    const penaltyFields = [
      pet.livePet,
      pet.pedigreeCertificate,
      pet.DNACertificate,
    ];
    const penalty =
      penaltyFields.filter((field) => !field || field === "").length * 10;
    let finalPercentage = basePercentage - penalty;
    finalPercentage = Math.max(finalPercentage, 0);
    return finalPercentage.toFixed(2);
  };
  const handleClick = () => {
    if (onCardClick) {
      onCardClick(petData);
    }
    setIspetInfoPopupOpen(true)
  };
  return (
    <>
      <ToastContainer />
      <section className={`${styles.petProfileCard} `}  onClick={(e) => { e.stopPropagation(); if (type === "match") {handleClick();setIspetInfoPopupOpen(true); }}} style={{ cursor: type === "match" ? "pointer" : "", width: type === "match" ? "23%" : "30%", }}>
        {petData.petStatus === "Hide" && (
          <div className={styles.hiddenBand}>
            <p>Hide</p>
          </div>
        )}
        <img   src={petData.petImage ? `${BACKEND_URL}${petData.petImage}` : "https://img.freepik.com/premium-vector/no-dog-allowed-sign_36244-493.jpg?semt=ais_hybrid"} alt="Pet Profile" className={`${petData.petStatus === "Hide" ? styles.hiddenCard : ""}`}     
         />
        <div className={styles.petProfileCard2}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>{petData.petName}</h1>
            {type === "match" ? (
              <div style={{ display: "flex", gap: "15px" }}>
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsChatPopupOpen(true);
                  }}
                >
                  <ChatIcon />
                </div>
                <div onClick={(e)=>{ e.stopPropagation();  handleClickLike(petData._id,petData.fav)}}> {petData?.fav ==="yes" ? <LikedIcon /> : <LikeIcon />}</div>
              </div>
            ) : (
              <div style={{ cursor: "pointer", position: "relative" }}>
                <div onClick={handleMenuClick}>
                  <MenuIcon />
                </div>
                {isOptionsMenuOpen && selectedPetData && (
                  <div ref={menuRef}>
                    <OptionsPopup  onClose={closeOptionsMenu} petData={selectedPetData} />
                  </div>
                )}
              </div>
            )}
          </div>
          <p style={{marginTop:"5px"}}>
            {petData.breed}, {petData.petAge}, {petData.petGender}
          </p>
          <div style={{ display: "flex", gap: type === "match" ? "5px" : "15px" }} >
            {type === "match" && (
              <div style={{ marginTop: "3px" }}>
                <LocationIcon />
              </div>
            )}
            {isEditingLocation ? (
              <input type="text" value={location} onChange={handleLocationChange} onBlur={handleLocationSubmit} autoFocus className={styles.locationInput} />
            ) : (
              <p>{location}</p>
            )}
            {type !== "match" && (
              <div style={{ cursor: "pointer", marginTop: "-4px" }} onClick={handleEditLocation} className={`${ petData.petStatus === "Hide" ? styles.hiddenCard : "" }`} >
                <EditIcon />
              </div>
            )}
          </div>
          {type !== "match" && (
            <>
              <div style={{ display: "flex", justifyContent: "space-between" }} className={`${  petData.petStatus === "Hide" ? styles.hiddenCard : ""  }`} >
                <p style={{ fontSize: "9px", marginBottom: "2px" }}>
                  Profile Completion
                </p>
                <p style={{ fontSize: "9px", fontWeight: 600, marginBottom: "2px", }}  >
                  {calculateProfileCompletion(petData)}%
                </p>
              </div>
              <div className={styles.progressBar1}>
                <div style={{ backgroundColor: calculateProfileCompletion(petData) === "100.00" ? "#209832" : "#1FBFC2", width: `${calculateProfileCompletion(petData)}%`,  height: "5px", }} ></div>
                <div className={`${styles.StatusPill}`} style={{ width: "60px", backgroundColor: calculateProfileCompletion(petData) === "100.00" ? "#a7f69d " : "#1FBFC21A",
                 //  width: `${calculateProfileCompletion(petData)}%`,
                 overflow: "visible",
                    position: "absolute",
                    top: "-15px",
                    left: "35%",
                    textAlign: "center",
                    padding: "2px 7px",
                    cursor: "pointer"
                  }} onClick={() => {
                    if (petData.petStatus !== "Hide") {
                      setSelectedPetData({});
                      setTimeout(() => {
                        setSelectedPetData(petData);
                      }, 0);
                      setIsNCPopupOpen(true);
                    } else {
                      console.log(
                        "Action disabled because the pet status is hidden."
                      );
                    }
                  }}
                >
                  <p
                    className={`${
                      petData.petStatus === "Hide" ? styles.hiddenCard : ""
                    }`} style={{ color: calculateProfileCompletion(petData) === "100.00" ? "#209832" : "#1FBFC2", fontFamily: "Poppins", fontSize: "6px", fontWeight: "400", margin: "0px" }} >
                     {calculateProfileCompletion(petData) === "100.00" ? "Completed" : "Not Completed"}
               </p>
                </div>
              </div>
              <p style={{ color: "#1FBFC2" }}>
                You Got {petData.matches} Matches
              </p>

              <button onClick={handleFindMatchesClick} disabled={isButtonDisabled}className={`${  isButtonDisabled ? styles.disabledButton : ""}  , ${petData.petStatus === "Hide" ? styles.hiddenCard : ""}`} >
                Find Matches
              </button>
            </>
          )}
        </div>
        <NotCompletedPopup isNCPopupOpen={isNCPopupOpen} setIsNCPopupOpen={setIsNCPopupOpen} setIsUpdatePopupOpen={setIsUpdatePopupOpen} setPhotoUrl={setImageUrl} setClipUrl={setVideoUrl} petData={selectedPetData} />
      </section>
    </>
  );
};

export default PetProfileCard;